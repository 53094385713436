.header {
   background: rgba(0, 0, 0, 0.75);
   color: $jade;
   padding: 16px 0 8px;
   top: 0;
   position: fixed;
   width: 100%;
   z-index: 999;
   border-bottom: 1px solid $jade;

   @media (min-width: $mobile) {
      padding: 16px 0;
      border: 0;
   }
}

.header__inner {
   @media (min-width: $mobile) {
      display: flex;
      justify-content: space-between;
   }
}

.header__heading {
   display: flex;
   justify-content: space-between;

   @media (min-width: $mobile) {
      text-align: left;
      display: block;
   }
}

.header__title {
   color: $cararra;
   font-size: 14px;
   font-weight: bold;

   @media (min-width: $small-mobile) {
      font-size: 24px;
   }
}

.header__icons {
   padding: 15px 0 0;
}

.header__icon {
   font-size: 19px;
   padding: 0 10px 0 0;
   text-decoration: none;

   @media (min-width: $small-mobile) {
      font-size: 24px;
   }
}

.header__down {
   display: block;
   font-size: 22px;
   text-align: center;
   cursor: pointer;
   @include transition(transform 0.3s ease-in-out);

   &.is-active {
      transform: rotateX(180deg);
   }

   @media (min-width: $mobile) {
      display: none;
   }
}

.header__name {
   margin: 0;
   font-size: 24px;
   @include transition(all 0.3s);

   @media (min-width: $small-mobile) {
      font-size: 32px;
   }
}

.header__nav {
   display: block;
   text-align: center;
   height: 0;
   visibility: hidden;
   overflow: hidden;
   position: relative;
   @include transition(all 0.3s ease-in-out);
   font-size: 22px;
   display: flex;
   flex-direction: column;
   justify-content: center;

   &.is-active {
      height: 145px;
      visibility: visible;
      padding: 10px 0 0;
   }

   @media (min-width: $mobile) {
      height: auto;
      visibility: visible;
      padding: 16px 0 0;
      text-align: left;
      display: block;
      font-size: 18px;
   }
}

.header__navtab {
   font-family: "Josefin Sans";
   font-size: 20px;
   color: $cararra;
   background: none;
   cursor: pointer;
   text-decoration: none;
   padding: 8px;
   border: 0;
   @include transition(all 0.3s);

   @media (min-width: $mobile) {
      display: inline-block;

      &:hover,
      &:focus,
      &:active {
         color: $jade;
         @include transition(color 0.3s);
      }
   }
}

.navigation-line {
   width: 75px;
   height: 2px;
   background: $jade;
   border-radius: 3px;
   display: none;

   @media (min-width: $mobile) {
      display: block;
   }
}

/*

.spin {
  width: 5em;
  height: 5em;
  padding: 0;

  &:hover {
    color: $blue;
  }

  &::before,
  &::after {
    top: 0;
    left: 0;
  }

  &::before {
    border: 2px solid transparent; // We're animating border-color again
  }

  &:hover::before {
    border-top-color: $blue; // Show borders
    border-right-color: $blue;
    border-bottom-color: $blue;

    transition:
      border-top-color 0.15s linear, // Stagger border appearances
      border-right-color 0.15s linear 0.10s,
      border-bottom-color 0.15s linear 0.20s;
  }

  &::after {
    border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
  }

  &:hover::after {
    border-top: 2px solid $blue; // Shows border
    border-left-width: 2px; // Solid edges, invisible borders
    border-right-width: 2px; // Solid edges, invisible borders
    transform: rotate(270deg); // Rotate around circle
    transition:
      transform 0.4s linear 0s,
      border-left-width 0s linear 0.35s; // Solid edge post-rotation
  }
}

.circle {
  border-radius: 100%;
  box-shadow: none;

  &::before,
  &::after {
    border-radius: 100%;
  }
}
*/
