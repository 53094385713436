//colors
$salem: #057944;
$old-gold: #d4af37;
$cararra: #efeeea;
$nobel: #b8b7b7;
$black: #000;
$jade: #09b96a;
$spring-green: #09ec86;
$edward: #a5b3ac;
$alto: #cecece;
$monza: #c70027;

//breakpoints
$small-mobile: 375px;
$mobile: 768px;
