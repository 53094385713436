@font-face {
   font-family: "icomoon";
   src: url("../fonts/icomoon/icomoon.eot?j3l86m");
   src: url("../fonts/icomoon/icomoon.eot?j3l86m#iefix")
         format("embedded-opentype"),
      url("../fonts/icomoon/icomoon.ttf?j3l86m") format("truetype"),
      url("../fonts/icomoon/icomoon.woff?j3l86m") format("woff"),
      url("../fonts/icomoon/icomoon.svg?j3l86m#icomoon") format("svg");
   font-weight: normal;
   font-style: normal;
   font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
   /* use !important to prevent issues with browser extensions that change fonts */
   font-family: "icomoon" !important;
   speak: never;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;

   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-down:before {
   content: "\e901";
}
.icon-arrow-down:before {
   content: "\e900";
}
.icon-arrow-up:before {
   content: "\ea32";
}
.icon-mail:before {
   content: "\ea84";
}
.icon-github:before {
   content: "\eab0";
}
.icon-linkedin:before {
   content: "\eac9";
}
